import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Cta } from '../components/Cta';
import { FeatureOne } from '../components/FeatureOne';
import { FeatureTwo } from '../components/FeatureTwo';
import { Footer } from '../components/Footer';
import { TeamSection } from '../components/TeamSection';
import { Transition } from '@headlessui/react';
export default function IndexPage() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div
        className='relative h-screen overflow-hidden'
        style={{
          backgroundImage: "url('/almond_grove.jpeg')",
          backgroundPosition: 'center',
        }}
      >
        <div className='relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32'>
          <div className='max-w-screen-xl mx-auto px-4 sm:px-6'>
            <nav className='relative flex items-center justify-between sm:h-10 md:justify-end'>
              <div className='flex items-center flex-1 md:absolute md:inset-y-0 md:left-0'>
                <div className='flex items-center justify-between w-full md:w-auto'>
                  <Link to='/'>
                    <img
                      src='/twai-logo.jpg'
                      className='rounded-md'
                      alt='The Water Agency Inc. Logo'
                    />
                  </Link>
                  <div className='-mr-1 flex items-center md:hidden'>
                    <button
                      type='button'
                      className='inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-black bg-white hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                      id='main-menu'
                      aria-label='Main menu'
                      aria-haspopup='true'
                      onClick={() => setShowMenu(true)}
                    >
                      <svg
                        className='h-8 w-8'
                        stroke='black'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M4 6h16M4 12h16M4 18h16'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className='hidden md:flex md:space-x-10 bg-white p-2 rounded-md'>
                <Link
                  to='/'
                  className='font-medium text-gray-800 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Home
                </Link>
                <Link
                  to='/water-supply-update'
                  className='font-medium text-gray-800 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Water Supply Update
                </Link>
                <Link
                  to='/contact'
                  className='font-medium text-gray-800 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Contact Us
                </Link>
              </div>
            </nav>
          </div>

          {/* <!--
      Mobile menu, show/hide based on menu open state.

      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    --> */}
          <Transition
            show={showMenu}
            enter='duration-200 ease-out'
            enterFrom='opacity-0 scale-50'
            enterTo='opacity-100 scale-100'
            leave='duration-200 ease-in'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-50'
          >
            <div className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'>
              <div className='rounded-lg shadow-md'>
                <div
                  className='rounded-lg bg-white shadow-xs overflow-hidden'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='main-menu'
                >
                  <div className='px-5 pt-4 flex items-center justify-between'>
                    <div>
                      <h3 className='text-2xl font-bold text-blue-600'>Menu</h3>
                    </div>
                    <div className='-mr-2'>
                      <button
                        type='button'
                        className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                        aria-label='Close menu'
                        onClick={() => setShowMenu(false)}
                      >
                        <svg
                          className='h-6 w-6'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className='px-2 pt-2 pb-3'>
                    <Link
                      to='/'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Home
                    </Link>
                    <Link
                      to='/water-supply-update'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Water Supply Update
                    </Link>
                    <Link
                      to='/contact'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          <div className='mt-20 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-40 lg:mt-40 xl:mt-40'>
            <div className='text-center bg-white rounded-xl p-2 lg:p-10 bg-opacity-85'>
              <h2 className='text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl'>
                We Make{' '}
                <span className='text-blue-600'>Water Transactions</span> Easy
              </h2>
              <p className='mt-3 max-w-md mx-auto text-base text-gray-900 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
                The Water Agency, Inc. has been facilitating farm water
                transactions in California's Central Valley for more than 20
                years. Want to buy or sell water? We're the team you can trust.
              </p>
              <div className='mt-5 mx-auto sm:flex sm:justify-center md:mt-8'>
                <div className='rounded-md mx-2 shadow-xl'>
                  <Link
                    to='/contact'
                    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 shadow-xl'
                  >
                    Contact Us
                  </Link>
                </div>
                <div className='mt-3 rounded-md shadow-xl sm:mt-0 sm:ml-3'>
                  <Link to="/water-supply-update" className='w-full flex items-center justify-center px-8 py-3 text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:bg-gray-100 border-blue-600 border hover:text-blue-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 shadow-xl'>
                    Get The Water Supply Update
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto'>
        <FeatureOne />
        <TeamSection />
        <FeatureTwo />
        <Cta />
        <Footer />
      </div>
    </>
  );
}
