import { Link } from 'react-router-dom';

export const Cta = () => (
  <>
    <h2 className='text-center text-4xl font-extrabold mt-10'>
      Ready to <span className='text-blue-600'>Learn More?</span>
    </h2>
    <p className='text-center text-xl font-bold'>We'd love to hear from you!</p>
    <div className='block sm:flex justify-center my-5'>
      <div className='rounded-md shadow-xl mx-2'>
        <Link
          to='/contact'
          className='w-full flex items-center justify-center px-4 sm:px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 shadow-xl'
        >
          Contact Us
        </Link>
      </div>
      <div className='rounded-md shadow-xl mx-2 sm:mt-0 sm:ml-3'>
        <Link
          to='/water-supply-update'
          className='w-full flex items-center justify-center px-4 sm:px-8 py-3 text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:bg-gray-100 border-blue-600 border hover:text-blue-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 shadow-xl'
        >
          Get The Water Supply Update
        </Link>
      </div>
    </div>
  </>
);
