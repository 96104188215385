import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ContactPage } from './pages/Contact';
import IndexPage from './pages/Index';
import { WaterSupplyUpdatePage } from './pages/WaterSupplyUpdate';

export const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/water-supply-update" component={WaterSupplyUpdatePage} />
      </Switch>
    </BrowserRouter>
  );
};
