import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';

export const ContactPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div
        className='relative min-h-screen'
        style={{
          backgroundPosition: 'center',
          backgroundImage: "url('/almond_grove.jpeg')",
        }}
      >
        <div className='relative pt-6 pb-12'>
          <div className='max-w-screen-xl mx-auto px-4 sm:px-6'>
            <nav className='relative flex items-center justify-between sm:h-10 md:justify-end'>
              <div className='flex items-center flex-1 md:absolute md:inset-y-0 md:left-0'>
                <div className='flex items-center justify-between w-full md:w-auto'>
                  <Link to='/'>
                    <img
                      src='/twai-logo.jpg'
                      className='rounded-md'
                      alt='The Water Agency Inc. Logo'
                    />
                  </Link>
                  <div className='-mr-1 flex items-center md:hidden'>
                    <button
                      type='button'
                      className='inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-black bg-white hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                      id='main-menu'
                      aria-label='Main menu'
                      aria-haspopup='true'
                      onClick={() => setShowMenu(true)}
                    >
                      <svg
                        className='h-8 w-8'
                        stroke='black'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M4 6h16M4 12h16M4 18h16'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className='hidden md:flex md:space-x-10 bg-white p-2 rounded-md'>
                <Link
                  to='/'
                  className='font-medium text-gray-800 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Home
                </Link>
                <Link
                  to='/water-supply-update'
                  className='font-medium text-gray-800 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Water Supply Update
                </Link>
                <Link
                  to='/contact'
                  className='font-medium text-blue-600 hover:text-blue-6000 transition duration-150 ease-in-out'
                >
                  Contact Us
                </Link>
              </div>
            </nav>
          </div>

          {/* <!--
    Mobile menu, show/hide based on menu open state.

    Entering: "duration-150 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  --> */}
          <Transition
            show={showMenu}
            enter='duration-200 ease-out'
            enterFrom='opacity-0 scale-50'
            enterTo='opacity-100 scale-100'
            leave='duration-200 ease-in'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-50'
          >
            <div className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'>
              <div className='rounded-lg shadow-md'>
                <div
                  className='rounded-lg bg-white shadow-xs overflow-hidden'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='main-menu'
                >
                  <div className='px-5 pt-4 flex items-center justify-between'>
                    <div>
                      <h3 className='text-2xl font-bold text-blue-600'>Menu</h3>
                    </div>
                    <div className='-mr-2'>
                      <button
                        type='button'
                        className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                        aria-label='Close menu'
                        onClick={() => setShowMenu(false)}
                      >
                        <svg
                          className='h-6 w-6'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className='px-2 pt-2 pb-3'>
                    <Link
                      to='/'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Home
                    </Link>
                    <Link
                      to='/water-supply-update'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Water Supply Update
                    </Link>
                    <Link
                      to='/contact'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          <div className='mt-20 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-40 lg:mt-40 xl:mt-40'>
            <div className='text-center bg-white rounded-xl p-2 lg:p-10 bg-opacity-85'>
              <h2 className='text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl'>
                Get <span className='text-blue-600'>In Touch</span> With Us
              </h2>
              <p className='mt-3 max-w-md mx-auto text-base text-gray-900 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
                Questions about what we do? Looking to buy or sell farm water?
                We'd love to hear from you. Fill out the form below and we'll be
                in touch soon.
              </p>
              <div className='flex items-start justify-around'>
                <div className='lg:flex lg:items-start lg:justify-around'>
                  <div className='flex flex-col justify-between'>
                    <h3 className='text-xl my-5'>If you prefer to call or write:</h3>
                    <a
                      className='text-lg text-blue-500'
                      href='tel:+15594388418'
                    >
                      Office Phone: +1 (559) 438-8418
                    </a>
                    <a
                      className='text-lg text-blue-500'
                      href='tel:+15599068418'
                    >
                      Erick H Johnson Cell: +1 (559) 906 8418
                    </a>
                    <a
                      className='text-lg text-blue-500'
                      href='tel:+15595752562'
                    >
                      Mitch Partovi Cell: +1 (559) 575-2562
                    </a>
                    <span
                      className='text-lg'
                      href='tel:+15595752562'
                    >
                      Office Address: 455 West Fir Avenue, Clovis CA 93611
                    </span>
                  </div>
                </div>
                <form action='https://formspree.io/f/meqpzqkw' method='POST'>
                  <h3 className='text-xl my-5'>Or fill out this form & we'll reach out</h3>
                  <div className='max-w-md mx-auto sm:flex sm:justify-between sm:align-middle'>
                    <div>
                      <input
                        required
                        name='First Name'
                        type='text'
                        className='py-4 px-3 w-full text-center border border-black'
                        placeholder='Your First Name'
                      />
                    </div>
                    <div className='mb-5 sm:mb-0'>
                      <input
                        required
                        name='Last Name'
                        type='text'
                        className='py-4 px-3 w-full text-center border border-black'
                        placeholder='Your Last Name'
                      />
                    </div>
                  </div>
                  <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-between sm:align-middle md:mt-8'>
                    <div className='mb-5 sm:mb-0'>
                      <input
                        required
                        name='Email'
                        type='text'
                        className='py-4 px-3 w-full text-center border border-black'
                        placeholder='Your Email'
                      />
                    </div>
                    <div className='mb-5 sm:mb-0'>
                      <input
                        required
                        name='Phone #'
                        type='text'
                        className='py-4 px-3 w-full text-center border border-black'
                        placeholder='Your Phone #'
                      />
                    </div>
                  </div>
                  <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-between sm:align-middle md:mt-8'>
                    <textarea
                      name='Message'
                      placeholder='Your Message Here (optional)'
                      className='w-full text-left p-3 border border-black'
                    />
                  </div>
                  <div className='max-w-md mx-auto flex justify-center mt-5'>
                    <button
                      className='w-full flex items-center justify-center px-8 py-3 text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:bg-gray-100 border-blue-600 border hover:text-blue-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 shadow-xl'
                      type='submit'
                    >
                      Contact Us!
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto' />
    </>
  );
};
