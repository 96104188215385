export const FeatureTwo = () => (
  <>
    <div className='relative bg-white'>
      <div className='lg:absolute lg:inset-0'>
        <div className='lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2'>
          <img
            className='h-56 w-full object-cover lg:absolute lg:h-full'
            src='/field.jpg'
            alt=''
          />
        </div>
      </div>
      <div className='relative pt-8 pb-8 px-4 sm:pt-12 sm:pb-12 sm:px-12 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2'>
        <div className='lg:col-start-2 lg:pl-8'>
          <div className='text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0 text-center md:text-left'>
            {/* <p className="leading-6 text-blue-600 font-semibold tracking-wide uppercase">
                  The Water Agency, Inc.
                </p> */}
            <h3 className='mt-2 mb-8 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10'>
              Why Should <span className='text-blue-600'>You Work with</span>{' '}
              The Water Agency?
            </h3>
            <ul className='list-disc prose text-gray-800 text-lg'>
              <li>
                <span className='font-bold'>
                  We take the time to understand
                </span>{' '}
                your specific needs.
              </li>
              <li>
                <span className='font-bold'>
                  We believe trust is earned, not given,
                </span>{' '}
                and we act accordingly.
              </li>
              <li>
                <span className='font-bold'>We have unparalleled insights</span>{' '}
                into water pricing and availability that we use to your
                advantage.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
);
