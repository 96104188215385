import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
export const WaterSupplyUpdatePage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [success, setSuccess] = useState(false);
  const [freeTier, setFreeTier] = useState(false);
  const [invoice, setInvoice] = useState(false);

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    };
    xhr.send(data);
  };

  const createAnnualSubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: 'P-68M46379ND265002GMAYUNJQ',
    });
  };
  const createMonthlySubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: 'P-02622496UU378122KMAWFFUY',
    });
  };

  const paypal = window.paypal;
  const PayPalButton = paypal.Buttons.driver('react', { React, ReactDOM });

  return (
    <>
      <div
        className='relative h-screen'
        style={{
          backgroundPosition: 'center',
          backgroundImage: "url('/almond_grove.jpeg')",
        }}
      >
        <div className='relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32'>
          <div className='max-w-screen-xl mx-auto px-4 sm:px-6'>
            <nav className='relative flex items-center justify-between sm:h-10 md:justify-end'>
              <div className='flex items-center flex-1 md:absolute md:inset-y-0 md:left-0'>
                <div className='flex items-center justify-between w-full md:w-auto'>
                  <Link to='/'>
                    <img
                      src='/twai-logo.jpg'
                      className='rounded-md'
                      alt='The Water Agency Inc. Logo'
                    />
                  </Link>
                  <div className='-mr-1 flex items-center md:hidden'>
                    <button
                      type='button'
                      className='inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-black bg-white hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                      id='main-menu'
                      aria-label='Main menu'
                      aria-haspopup='true'
                      onClick={() => setShowMenu(true)}
                    >
                      <svg
                        className='h-8 w-8'
                        stroke='black'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M4 6h16M4 12h16M4 18h16'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className='hidden md:flex md:space-x-10 bg-white p-2 rounded-md'>
                <Link
                  to='/'
                  className='font-medium text-gray-800 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Home
                </Link>
                <Link
                  to='/water-supply-update'
                  className='font-medium text-blue-600 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Water Supply Update
                </Link>
                <Link
                  to='/contact'
                  className='font-medium text-gray-800 hover:text-blue-600 transition duration-150 ease-in-out'
                >
                  Contact Us
                </Link>
              </div>
            </nav>
          </div>

          {/* <!--
      Mobile menu, show/hide based on menu open state.

      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    --> */}
          <Transition
            show={showMenu}
            enter='duration-200 ease-out'
            enterFrom='opacity-0 scale-50'
            enterTo='opacity-100 scale-100'
            leave='duration-200 ease-in'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-50'
          >
            <div className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'>
              <div className='rounded-lg shadow-md'>
                <div
                  className='rounded-lg bg-white shadow-xs overflow-hidden'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='main-menu'
                >
                  <div className='px-5 pt-4 flex items-center justify-between'>
                    <div>
                      <h3 className='text-2xl font-bold text-blue-600'>Menu</h3>
                    </div>
                    <div className='-mr-2'>
                      <button
                        type='button'
                        className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                        aria-label='Close menu'
                        onClick={() => setShowMenu(false)}
                      >
                        <svg
                          className='h-6 w-6'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className='px-2 pt-2 pb-3'>
                    <Link
                      to='/'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Home
                    </Link>
                    <Link
                      to='/water-supply-update'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Water Supply Update
                    </Link>
                    <Link
                      to='/contact'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                      role='menuitem'
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          <div className='mt-20 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-40 lg:mt-40 xl:mt-40'>
            <div className='text-center bg-gray-100 rounded-xl p-2 lg:p-10 bg-opacity-85'>
              <h2 className='text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl'>
                Join Us
              </h2>
              {!success && (
                <>
                  <div className='mt-3 max-w-md mx-auto text-base text-left text-gray-900 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
                    <h3 className='text-2xl text-blue-500'>Become a Partner</h3>
                    <p className='my-2'>
                      Please fill out the form below to become a supporting
                      partner for the Premium Water Supply Update. Partnership
                      dues are only $35.00 per month or $360.00 per year.
                    </p>
                    <h3 className='text-2xl mt-4 text-blue-500'>
                      Partnership Benefits
                    </h3>
                    <ul className='list-disc pl-5'>
                      <li>
                        Your partnership supports a good cause – advocacy for
                        agricultural water.
                      </li>
                      <li>
                        The Premium Water Supply Update contains more
                        information than the Headliner Water Supply Update.
                      </li>
                      <li>
                        All of the information you need is consolidated into one
                        report.
                      </li>
                      <li>You save time researching the status of water.</li>
                    </ul>
                    <small className='text-sm'>
                      Not ready to become a supporting partner for the Premium
                      Water Supply Update? That’s O.K. Please check the box on
                      the form below to indicate your preference to receive the
                      Headliner Water Supply Update.
                    </small>
                  </div>
                  <form
                    onSubmit={submitForm}
                    action='https://formspree.io/f/xzbkqrrp'
                    method='POST'
                  >
                    <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-between sm:align-middle md:mt-8'>
                      <div className='mb-5 sm:mb-0 w-full'>
                        <input
                          required
                          name='Company'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='Company Name'
                        />
                      </div>
                    </div>
                    <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-between sm:align-middle md:mt-8'>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='First Name'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='First Name'
                        />
                      </div>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='Last Name'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='Last Name'
                        />
                      </div>
                    </div>
                    <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-between sm:align-middle md:mt-8'>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='Email'
                          type='email'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='Email'
                        />
                      </div>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='Phone'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='Phone Number'
                        />
                      </div>
                    </div>
                    <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-between sm:align-middle md:mt-8'>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='Address'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='Address'
                        />
                      </div>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='City'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='City'
                        />
                      </div>
                    </div>
                    <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-between sm:align-middle md:mt-8'>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='State'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='State'
                        />
                      </div>
                      <div className='mb-5 sm:mb-0'>
                        <input
                          required
                          name='ZIP'
                          type='text'
                          className='py-4 px-3 w-full text-center border border-black'
                          placeholder='Zip Code'
                        />
                      </div>
                    </div>
                    <div className='max-w-md mx-auto flex items-center justify-center mt-5'>
                      <input
                        className='w-8 h-8'
                        name='freeTier'
                        checked={freeTier}
                        onChange={(e) => setFreeTier(e.target.checked)}
                        type='checkbox'
                      />
                      <label className='px-2'>
                        I only want the Headliner version of the Water Supply
                        Update.
                      </label>
                    </div>

                    <div className='max-w-md mx-auto flex items-center justify-center mt-5'>
                      <input
                        className='w-8 h-8'
                        name='invoice'
                        checked={invoice}
                        onChange={(e) => setInvoice(e.target.checked)}
                        type='checkbox'
                      />
                      <label className='px-2'>
                        Send me an Annual Invoice for the Premium Water Supply Update
                      </label>
                    </div>

                    <div className='max-w-md mx-auto flex justify-center mt-5'>
                      <button
                        className='w-full flex items-center justify-center px-8 py-3 text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:bg-gray-100 border-blue-600 border hover:text-blue-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 shadow-xl'
                        type='submit'
                      >
                        {freeTier
                          ? 'Subscribe to the Free Headliner'
                          : invoice
                          ? 'Invoice Me'
                          : 'Pay with PayPal or Credit Card'}
                      </button>
                    </div>
                  </form>
                </>
              )}
              {success && !freeTier && !invoice && (
                <>
                  <h3 className='text-2xl mt-2'>Step #2 - Payment</h3>
                  <div className='grid md:grid-cols-2 gap-4'>
                    <div>
                      <h4 className='my-2 text-lg'>
                        Monthly Subscription ($35/Month)
                      </h4>
                      <PayPalButton
                        createSubscription={(data, actions) =>
                          createMonthlySubscription(data, actions)
                        }
                      />
                    </div>
                    <div>
                      <h4 className='my-2 text-lg'>
                        Annual Subscription ($360/year){' '}
                        <span className='text-blue-700'>- Save $60/year!</span>
                      </h4>
                      <PayPalButton
                        createSubscription={(data, actions) =>
                          createAnnualSubscription(data, actions)
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {success && freeTier && (
                <>
                  <h3 className='text-2xl mt-2'>Complete!</h3>
                  <p className='text-gray-800 max-w-xl mx-auto my-2'>
                    You've subscribed to the Headliner version of the Water
                    Supply Update - exepct to recieve your first issue soon!
                  </p>
                </>
              )}
              {success && invoice && !freeTier && (
                <>
                  <h3 className='text-2xl mt-2'>Complete!</h3>
                  <p className='text-gray-800 max-w-xl mx-auto my-2'>
                    Thanks for becoming a Water Supply Update partner.
                    We'll send you an invoice in the mail once annually.
                    Expect your first issue soon!
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto'></div>
    </>
  );
};
