export const FeatureOne = () => (
  <>
    <div className='bg-white my-12' id='about'>
      <div className='max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='lg:text-center'>
          <h3 className='mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 text-center sm:text-4xl sm:leading-10'>
            <span className='font-black text-blue-600'>The Better Way</span> to
            Buy & Sell farm water
          </h3>
        </div>
      </div>
    </div>
    <div className='relative bg-white'>
      <div className='lg:absolute lg:inset-0'>
        <div className='lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2'>
          <img
            className='h-56 w-full object-cover lg:absolute lg:h-full'
            src='/vineyards.jpg'
            alt=''
          />
        </div>
      </div>
      <div className='relative pt-8 pb-8 px-4 sm:pt-12 sm:pb-12 sm:px-12 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2'>
        <div className='lg:col-start-2 lg:pl-8'>
          <div className='text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0 text-center md:text-left'>
            <h3 className='mt-2 mb-8 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10'>
              What <span className='text-blue-600'>We Can Do</span> for You
            </h3>
            <ul className='prose list-disc text-gray-800 text-lg'>
              <li>
                <span className='font-bold'>We help our clients</span> navigate
                the complicated process of buying and selling farm water.
              </li>
              <li>
                The Water Agency Team has{' '}
                <span className='font-bold'>20+ years of experience</span>{' '}
                working with hundreds of farmers and water agencies.
              </li>
              <li>
                Our office is located in the{' '}
                <span className='font-bold'>San Joaquin Valley</span>, deep in
                California's heartland.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
);
