export const TeamSection = () => (
  <div className="bg-white">
    <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:py-24">
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="space-y-5 sm:space-y-4 grid">
          <h2 className="text-2xl pt-4 leading-9 font-extrabold tracking-tight sm:text-3xl">
            The <span className="text-blue-600">Water Agency</span> Team
          </h2>
          <p className="text-lg leading-8 text-gray-700">
            Founded in 2000, The Water Agency Inc. team has spent the last 20 years making buying and selling farm water both easy and worry-free.
          </p>
        </div>
        <div className="lg:col-span-2">
          <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
            <li>
              <div className="flex items-center justify-end space-x-4 lg:space-x-6">
                <div className="font-medium text-right text-lg leading-6 space-y-1">
                  <h4>Erick Johnson</h4>
                  <p className="text-blue-600">President & Founder</p>
                </div>
                <img
                  className="w-16 h-16 rounded-full lg:w-20 lg:h-20 border"
                  src="/erick.jpg"
                  alt=""
                /> 
              </div>
            </li>

            <li>
              <div className="flex items-center justify-end space-x-4 lg:space-x-6">
                <div className="font-medium text-right text-lg leading-6 space-y-1">
                  <h4>Mitch Partovi</h4>
                  <p className="text-blue-600">Vice President</p>
                </div>
                <img
                  className="w-16 h-16 rounded-full lg:w-20 lg:h-20 border"
                  src="/mitch.jpg"
                  alt=""
                />
              </div>
            </li>

            <li>
              <div className="flex items-center justify-end space-x-4 lg:space-x-6">
                <div className="font-medium text-right text-lg leading-6 space-y-1">
                  <h4>Michele Calvert</h4>
                  <p className="text-blue-600">Office Manager</p>
                </div>
                <img
                  className="w-16 h-16 rounded-full lg:w-20 lg:h-20 border"
                  src="/michele.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
